import React from 'react';
import BreadcrumbSchema from '../../components/BreadcrumbSchema';
import { graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { FaEdit } from 'react-icons/fa';
import ServiceTemplate from '../../components/service/ServiceTemplate';

const WebNew = ({ data }) => {
  const seoData = {
    title: 'ホームページの更新代行｜奈良のホームページ制作なら｜インヴォルブ',
    description:
      'サイト運営の手間を削減し、ホームページの更新・修正作業を代行。最新情報の反映やコンテンツのメンテナンスを迅速に行い、常に最適な状態を維持します。',
    pageUrl: 'https://www.involve-in.jp/service/update',
    ogImage: 'https://www.involve-in.jp/images/common/ogp.png',
  };

  const breadcrumbList = [
    { name: 'ホーム', url: 'https://www.involve-in.jp/' },
    { name: 'サービス一覧', url: 'https://www.involve-in.jp/service/' },
    { name: 'ホームページの更新代行', url: seoData.pageUrl },
  ];

  const works = data.allContentfulCaseStudy.nodes; // Contentful から施工事例を取得

  return (
    <>
      {/* ✅ 構造化パンくずデータを渡す */}
      <BreadcrumbSchema breadcrumbList={breadcrumbList} />
      <ServiceTemplate
        title="ホームページの更新代行"
        subtitle="サイト運営をサポート"
        icon={FaEdit}
        description="サイト運営の手間を削減し、ホームページの更新・修正作業を代行。最新情報の反映やコンテンツのメンテナンスを迅速に行い、常に最適な状態を維持します。"
        seo={{
          title:
            'ホームページの更新代行｜奈良のホームページ制作なら｜インヴォルブ',
          description:
            'サイト運営の手間を削減し、ホームページの更新・修正作業を代行。最新情報の反映やコンテンツのメンテナンスを迅速に行い、常に最適な状態を維持します。',
          pageUrl: 'https://www.involve-in.jp/service/update',
          ogImage: 'https://www.involve-in.jp/images/common/ogp.png',
        }}
        breadcrumbs={[
          { name: 'ホーム', url: 'https://www.involve-in.jp/' },
          {
            name: 'サービス一覧',
            url: 'https://www.involve-in.jp/service/',
          },
          {
            name: 'ホームページの更新代行',
            url: 'https://www.involve-in.jp/service/update',
          },
        ]}
        benefits={[
          {
            title: 'コンテンツの更新',
            description:
              '新着情報・ブログ・各ページの修正を迅速かつスムーズに対応し、最新の状態を維持。',
          },
          {
            title: '画像・バナー変更',
            description:
              'キャンペーンや季節に合わせたデザイン変更にも柔軟に対応し、視覚的な訴求力を向上。',
          },
          {
            title: 'SEO維持・改善',
            description:
              '定期的なSEO調整を行い、検索順位や集客力の維持・向上をサポート。',
          },
        ]}
        flow={[
          {
            title: '更新内容の確認',
            duration: '即日〜1週間',
            description:
              'どのページを更新するか、修正内容を確認し、最適な対応プランを決定。',
          },
          {
            title: '修正・更新作業',
            duration: '1週間',
            description:
              'ご希望の変更内容を正確に反映し、必要に応じてテストを実施しながら更新。',
          },
          {
            title: '公開・運用',
            duration: '継続',
            description:
              '更新作業後に公開し、必要に応じて定期的なメンテナンスや追加修正をサポート。',
          },
        ]}
        preparation={[
          {
            title: '更新したいページのリストアップ',
            description:
              '修正が必要なページや具体的な更新内容を整理し、作業をスムーズに進められるように準備。',
          },
          {
            title: 'テキストや画像の準備',
            description:
              '掲載する新しい情報や画像を事前に用意し、迅速な更新を実現。',
          },
          {
            title: '更新頻度の決定',
            description:
              '月1回・週1回など、定期的な更新スケジュールを決め、計画的にサイトを最新の状態に維持。',
          },
          {
            title: '追加対応の確認',
            description:
              'SEO調整やデザイン変更など、必要に応じた追加対応の可否を事前に相談し、最適な運用を検討。',
          },
        ]}
        works={works.map((work) => ({
          title: work.title,
          image:
            work.image && work.image.length > 0
              ? getImage(work.image[0].gatsbyImageData)
              : null, // 🔥 最初の画像のみ取得
        }))}
        price="¥5,000〜"
        cta="まずは無料相談"
      />
      {/* ✅ 表示用のパンくずは ServiceTemplate で管理 */}
    </>
  );
};

// GraphQL クエリ（Contentful の施工事例を取得）
export const query = graphql`
  query {
    allContentfulCaseStudy(sort: { publishDate: DESC }) {
      nodes {
        title
        slug
        image {
          gatsbyImageData(
            layout: CONSTRAINED
            formats: [AUTO, WEBP]
            placeholder: BLURRED
          )
        }
      }
    }
  }
`;

export default WebNew;
